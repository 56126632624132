<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper">
      <div class="text-center font-16 m-t">
        Você está prestes a definir um <strong>novo comprador</strong> para os lotes selecionados
      </div>
      <div class="m-t-xxl font-13 font-bold m-b text-center">
        Lotes selecionados para transferência
      </div>
      <pendencias :arrematante="arrematante" simple :simple-data="lotes" class="bg-white"/>
      <div class="text-center m-t-lg">
        <div class="m-b font-16 font-bold">Digite o <strong>CPF ou CNPJ</strong> do novo titular</div>
        <div style="width: 356px; margin: 0 auto">
          <erp-input @keydown.enter="buscar" autofocus v-model="busca"
                     class="btn-pda btn-pda-rounded big text-center text-white"
                     shortkey="F2"/>
        </div>
        <div v-if="!loading" class="text-center">
          <pda-menu-item label="Iniciar processo" shortcut="ENTER" class="btn-pda-def text-white"
                         @click.native="buscar"/>
        </div>
        <div class="text-center" v-if="loading">
          <u-progress style="max-width: 300px; margin: auto" :percentage="loadingProgress" color="primary"
                      :animate="true"
                      v-if="loading"/>
          <div class="font-10 m-t-xxs">{{ loadingProgress.toFixed(0) }}%</div>
        </div>
        <div v-if="notFound" class="text-negative">Arrematante não encontrado</div>
        <e-row v-if="comprador" class="text-left m-t-xs">
          <e-col class="flex justify-start">
            <arrematante-card style="max-width: 350px" :arrematante="arrematante"/>
          </e-col>
          <e-col class="flex items-center justify-center" style="max-width: 100px; font-size: 80px">
            &raquo;
          </e-col>
          <e-col class="flex justify-end">
            <arrematante-card style="max-width: 350px" :arrematante="comprador"/>
          </e-col>
        </e-row>
        <e-row class="m-t-lg" v-if="comprador">
          <e-col>
            <u-btn @click="confirm" color="positive" size="lg"
                   :label="'Confirmar transferência de ' + lotes.length + ' lotes'"/>
            <u-btn @click="$uloc.window.close($root.wid)" color="grey" size="lg" label="Cancelar" class="m-l"/>
          </e-col>
        </e-row>
      </div>
    </div>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
    </div>
    <h5 class="m-t">Transferência realizada com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)"/>
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {
  WindowContent,
  ErpInput
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../../layout/window/Loading'
import TimerCloseWindow from '../../../../layout/window/TimerClose'
import WindowSuccess from '../../../../layout/window/Success'
import Pendencias from './Pendencias'
import loadingMixin from '../../../../../reuse/loading/loadingMixin'
import PdaMenuItem from '../../MenuItem'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import ArrematanteCard from './Card'
import {findByDoc} from '@/domain/arrematante/services'
import {transferirArremate} from '@/domain/leiloes/services'
// import appList from '../app';


export default {
  name: 'TransferenciaComprador',
  mixins: [loadingMixin],
  props: ['lotes', 'arrematante'],
  data() {
    return {
      status: 'Transferindo...',
      loading: false,
      errors: null,
      success: false,
      busca: null,
      finded: false,
      loadingProgress: 0,
      notFound: false,
      comprador: null
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    buscar() {
      if (!this.busca || this.busca.length === 0) {
        return
      }
      console.log('Pesquisar por arrematante: ', this.busca)
      // this.loading = true
      this.startLoading()
      this.notFound = false
      this.comprador = null
      findByDoc(this.busca.replace(/\D/g, ''))
          .then(response => {
            let data = response.data
            this.comprador = data
            this.stopLoading()
          })
          .catch((error) => {
            this.stopLoading()
            this.notFound = true
            this.loading = false
            console.error(error, error.data)
          })
    },
    confirm() {
      console.log('Save')
      this.loading = true
      this.lotes.map(loteArremate => {
        let lote = loteArremate
        if (!loteArremate.numero && loteArremate.lote && loteArremate.lote.numero) {
          lote = loteArremate.lote
        }
        transferirArremate(lote.id, this.comprador.id)
            .then(({data}) => {
              console.log(lote)
              this.$uloc.notify({
                color: 'positive',
                message: `Venda do lote ${lote.numero} alterado com sucesso.`
              })
              if (loteArremate.id === this.lotes[this.lotes.length - 1].id) {
                this.success = true
                this.$nextTick(() => {
                  this.$uloc.window.emit(this.$root.wid, 'update')
                  this.$refs.timer.start()
                })
              }
            })
            .catch((error) => {
              this.alertApiError(error)
              if (loteArremate.id === this.lotes[this.lotes.length - 1].id) {
                this.loading = false
                this.$uloc.window.emit(this.$root.wid, 'update')
              }
            })
      })
    }
  },
  components: {
    ArrematanteCard,
    ECol,
    ERow,
    PdaMenuItem,
    Pendencias,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowContent,
    ErpInput
  }
}
</script>
